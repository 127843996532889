import { AnalyticsBrowser } from "@segment/analytics-next";
import * as snippet from "@segment/snippet";
import { vwoMiddleware } from "../vwo/middleware";

let segmentUserAuthenticatedAnalytics: AnalyticsBrowser | undefined;
let segmentUserUnauthenticatedAnalytics: AnalyticsBrowser | undefined;

if (typeof window !== "undefined") {
  segmentUserAuthenticatedAnalytics = new AnalyticsBrowser();
  segmentUserUnauthenticatedAnalytics = new AnalyticsBrowser();

  segmentUserAuthenticatedAnalytics
    .load({
      writeKey: process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_KEY as string,
    })
    .then(() => {
      segmentUserAuthenticatedAnalytics?.addSourceMiddleware(vwoMiddleware());
    });

  segmentUserUnauthenticatedAnalytics
    .load({
      writeKey: process.env
        .NEXT_PUBLIC_SEGMENT_UNAUTHENTICATED_ANALYTICS_KEY as string,
    })
    .then(() => {
      segmentUserUnauthenticatedAnalytics?.addSourceMiddleware(vwoMiddleware());
    });
}

export {
  segmentUserAuthenticatedAnalytics,
  segmentUserUnauthenticatedAnalytics,
};

// // For unauthenticated events
// export const segmentUserUnauthenticatedAnalytics = ():
//   | AnalyticsSnippet
//   | undefined => {
//   // @ts-ignore
//   return typeof window !== "undefined" ? window?.analytics : undefined;
// };

// export const renderScript = () =>
//   snippet.min({
//     host: "cdn.segment.com",
//     apiKey: process.env.NEXT_PUBLIC_SEGMENT_UNAUTHENTICATED_ANALYTICS_KEY,
//     page: false,
//   });
