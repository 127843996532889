import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { gql } from "@apollo/client";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client/react";
import { CART } from "../lib/apollo/fragments";
import { sendSentryError } from "../lib/sentry/sentry";
import { DiscountT } from "../utils/shop";
import littledata from "@littledata/headless-shopify-sdk";
import { useRouter } from "next/router";
import { initializeApollo } from "@/lib/apollo/apollo-client";
import { facebookPixelId, getGAId } from "@/utils/pixel";
import { exponentialBackoff } from "@/utils/misc";
import { getCookie, setCookie } from "cookies-next";
import axios from "axios";
import { getCampaignData } from "@/utils/analytics";

export const CREATE_CART = gql`
  mutation cartCreate($input: CartInput) {
    cartCreate(input: $input) {
      cart {
        id
        checkoutUrl
      }
    }
  }
`;

const FETCH_CART = gql`
  ${CART}
  query Cart($cartId: ID!) {
    cart(id: $cartId) {
      estimatedCost {
        subtotalAmount {
          amount
          currencyCode
        }
      }
      ...CART
    }
  }
`;

const UPDATE_CART_ATTRIBUTES = gql`
  mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
    cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
      cart {
        id
        checkoutUrl
        attributes {
          key
          value
        }
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;

const REMOVE_FROM_CART = gql`
  ${CART}
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(lineIds: $lineIds, cartId: $cartId) {
      cart {
        ...CART
      }
    }
  }
`;

const FETCH_CART_LINES = gql`
  query Cart($cartId: ID!) {
    cart(id: $cartId) {
      lines(first: 20) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export enum ShopReducerTypes {
  CreateCart = "CREATE_CART",
  AddToCart = "ADD_TO_CART",
  UpdateCartItem = "UPDATE_CART_ITEM",
  DeleteCartItem = "REMOVE_CART_ITEM",
  AddDiscount = "ADD_DISCOUNT",
  Checkout = "CHECKOUT",
  AddAttributes = "ADD_ATTRIBUTES",
  SetCartError = "SET_CART_ERROR",
  ClearCartError = "CLEAR_CART_ERROR",
}

export interface CreateCartProps {
  variables: {
    input: {
      buyerIdentity?: {
        email?: string;
        phone?: string;
        countryCode?: string;
        deliveryAddressPreferences?: {
          deliveryAddress?: {
            address1?: string;
            address2?: string;
            city?: string;
            country?: string;
            firstName?: string;
            lastName?: string;
            phone?: string;
            province?: string;
            zip?: string;
          };
        };
      };
    };
  };
}

type CartType = {
  id: string;
  checkoutUrl: string;
  estimatedCost?: {
    subtotalAmount?: {
      amount?: string;
      currencyCode?: string;
    };
  };
};

type ShopStateType = {
  cart: CartType;
  discountMap: {
    [productId: string]: DiscountT & {
      code: string;
    };
  };
  cartAttributes: { key: string; value: string }[];
  cartError: string | null;
};

const initialState = {
  cart:
    typeof window !== "undefined"
      ? JSON.parse(String(getCookie("shop_cart") || "{}"))
      : {},
  discountMap: {},
  cartAttributes: [],
  cartError: null,
};

export const gaClientIdToShopifyAttributes = () => {
  const gaClientId = getGAId();
  const key = "_ga_client_id";
  if (!gaClientId) return null;

  return { key: key, value: gaClientId };
};

const addAttributesToCart = async (
  cartId: String, 
  attributes: { key: string; value: string }[]
) => {
  const gaAttribute = gaClientIdToShopifyAttributes();
  if (gaAttribute) {
    attributes.push(gaAttribute);
  }

  const apolloClient = initializeApollo();
  const context = { clientName: "shopify" };
  attributes = attributes.filter(attr => attr.value !== "");
  console.debug("Attempting to add attributes to cart", attributes, cartId);
  return await apolloClient.mutate({
    mutation: UPDATE_CART_ATTRIBUTES,
    variables: {
      attributes: attributes,
      cartId: cartId,
    },
    context: context,
  });
}

/**
 * Opens the Shopify checkout URL in a new tab after attempting to update the
 * cart with metadata from local storage if available.
 *
 * @param {ShopStateType} state - The current state of the shop,
 * containing the cart information.
 */
const redirectToCheckout = async (state: ShopStateType) => {
  console.log("Redirecting to checkout...");
  console.debug("Current state:", state);
  try {
    const result = await addAttributesToCart(state.cart.id, state.cartAttributes);
    console.debug("Cart updated", result);
    console.log("Cart metadata update successful");
  } catch (error) {
    console.error("Failed to update cart with metadata:", error);
    sendSentryError("Failed to update cart with metadata:", error);
    console.log("Error details:", JSON.stringify(error));
  }
  console.log("Opening checkout URL:", state.cart.checkoutUrl);
  window.open(state.cart.checkoutUrl, "_self");
};

const ShopReducer = (
  state: ShopStateType,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ShopReducerTypes.CreateCart:
      // Set cookie to expire in 7 days
      setCookie("shop_cart", JSON.stringify(payload), {
        maxAge: 60 * 60 * 24 * 7, // 7 days in seconds
        path: "/",
      });
      return { ...state, cart: payload, cartError: null };
    case ShopReducerTypes.AddToCart:
      return { ...state, cartError: null };
    case ShopReducerTypes.UpdateCartItem:
      return { ...state, cartError: null };
    case ShopReducerTypes.DeleteCartItem:
      return { ...state, cartError: null };
    case ShopReducerTypes.AddDiscount:
      return {
        ...state,
        cartError: null,
        discountMap: {
          ...state.discountMap,
          [payload.productId]: {
            value_type: payload.value_type,
            value: payload.value,
            code: payload.code,
          },
        },
      };
    case ShopReducerTypes.AddAttributes:
      if (!Array.isArray(payload)) {
        console.debug("Payload is not an array");
        return state;
      }
      console.debug("Updating cart attributes", payload);
      return {
        ...state,
        cartError: null,
        cartAttributes: [
          ...state.cartAttributes.filter(attr => !payload.some(p => p.key === attr.key)),
          ...payload
        ],
      };
    case ShopReducerTypes.Checkout:
      redirectToCheckout(state);
      return { ...state };
    case ShopReducerTypes.SetCartError:
      return { ...state, cartError: payload };
    case ShopReducerTypes.ClearCartError:
      return { ...state, cartError: null };
    default:
      return state;
  }
};

export const ShopContext = createContext<{
  state: ShopStateType;
  dispatch: React.Dispatch<any>;
  clearCart: () => Promise<void>;
  createCart: (data: CreateCartProps) => Promise<void>;
}>({
  state: initialState,
  dispatch: () => null,
  clearCart: async () => {},
  createCart: async () => {},
});

export const useShopContext = () => {
  const context = React.useContext(ShopContext);
  if (context === undefined) {
    throw new Error("useShopContext must be used within a ShopContextProvider");
  }
  return context;
};

//@ts-ignore
export const ShopContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ShopReducer, initialState);
  const [clientIdsLoaded, setClientIdsLoaded] = useState(false);
  const cartInitializeAttempted = useRef(false);
  const router = useRouter();

  const [createShopifyCart] = useMutation(CREATE_CART, {
    context: { clientName: "shopify" },
    onCompleted: async ({ cartCreate }) => {
      console.debug("cart created", cartCreate?.cart);
      dispatch({
        type: ShopReducerTypes.CreateCart,
        payload: cartCreate?.cart,
      });
      try {
        const campaignData = getCampaignData();
        const cartId = cartCreate?.cart?.id?.split("/")?.pop()?.split("?")[0];
        const result = await axios.post(
          `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/campaignData`,
          {
            cart_id: cartId,
            payload: campaignData,
          }
        );
        console.log("campaignData sent: ", result);
      } catch (error) {
        sendSentryError("Error sending campaign data", error);
      }
    },
    onError: (error) => {
      sendSentryError("shopify mutation cartCreate", error);
      console.error("Failed to create cart", error);
    },
  });

  const createCart = async (data: any) => {
    await createShopifyCart({
      variables: data?.variables,
    });
  };

  const linkCartToLittledata = (cartId: string) => {
    littledata
      .sendCartToLittledata(cartId)
      .then((shopifyCartNotes) => {
        if (shopifyCartNotes.length > 0) {
          console.debug("Cart: Succesfully linked to Littledata");
          dispatch({
            type: ShopReducerTypes.AddAttributes,
            payload: shopifyCartNotes,
          });
        }
      })
      .catch((error: any) => {
        sendSentryError("Error sending cart to Littledata", error);
      });
  };

  const clearCart = async () => {
    const cartId = state?.cart?.id;
    if (!cartId) return;

    const cartLineResult = await fetchCartLines({
      variables: {
        cartId: cartId,
      },
    });
    const cartLineIds = cartLineResult.data?.cart?.lines?.edges?.map(
      (edge: any) => edge.node.id
    );

    await cartLinesRemove({
      variables: {
        cartId: cartId,
        lineIds: cartLineIds,
      },
    });
  };

  const { data } = useQuery(FETCH_CART, {
    skip: !(
      typeof state?.cart?.id === "string" && state.cart.id.startsWith("gid")
    ),
    variables: {
      cartId: state?.cart?.id,
    },
    context: {
      clientName: "shopify",
    },
    onError: (error) => {
      sendSentryError("Failed to fetch cart from Shop Context", error);
    },
    onCompleted: async ({ cart }) => {
      console.log("cart fetched", cart);
      const campaignData = getCampaignData();
      try {
        // Extract cart ID from gid://shopify/Cart/[ID] format
        const cartId = cart?.id?.split("/")?.pop()?.split("?")[0];
        const result = await axios.post(
          `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/campaignData`,
          {
            cart_id: cartId,
            payload: campaignData,
          }
        );
        console.log("campaignData sent: ", result);
      } catch (error) {
        sendSentryError("Error sending campaign data", error);
      }
    },
  });

  const [cartLinesRemove] = useMutation(REMOVE_FROM_CART, {
    context: { clientName: "shopify" },
    onCompleted: ({ cartLinesRemove }) => {
      console.log("item removed from cart", cartLinesRemove);
      dispatch({
        type: ShopReducerTypes.DeleteCartItem,
        payload: cartLinesRemove,
      });
    },
    onError: (error) => {
      console.log("cartLinesRemove error", error);
    },
  });

  const [fetchCartLines] = useLazyQuery(FETCH_CART_LINES, {
    fetchPolicy: "no-cache",
    context: { clientName: "shopify" },
    onError: (error) => {
      console.error("fetchCartLines error", error);
    },
  });

  const initializeCart = useCallback(async () => {
    if (cartInitializeAttempted.current || state.cart.id) {
      return;
    }

    cartInitializeAttempted.current = true;
    console.debug("Initializing cart");

    try {
      await exponentialBackoff(createShopifyCart);
    } catch (error) {
      console.error("Failed to create cart after retries:", error);
      sendSentryError("Failed to create cart after retries", error);
      dispatch({
        type: ShopReducerTypes.SetCartError,
        payload: "Failed to initialize cart. Please refresh the page.",
      });
    }
  }, [createShopifyCart, state?.cart?.id]);

  useEffect(() => {
    initializeCart();
  }, [initializeCart]);

  useEffect(() => {
    const fetchedCartIsNull: boolean = data?.cart === null;
    if (!fetchedCartIsNull && clientIdsLoaded) {
      console.debug("Linking cart to Littledata");
      linkCartToLittledata(data?.cart?.id);
    }
  }, [data?.cart, clientIdsLoaded]);

  useEffect(() => {
    // Doesn't seem to resolve if ad block is enabled
    littledata
      .fetchClientIds({
        ga4MeasurementId: process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID,
        segmentWriteKey:
          process.env.NEXT_PUBLIC_SEGMENT_UNAUTHENTICATED_ANALYTICS_KEY,
        ...(process.env.NODE_ENV === "production" && facebookPixelId
          ? { fbPixelId: facebookPixelId }
          : {}),
      })
      .then((_clientIds: any) => {
        setClientIdsLoaded(true);
      })
      .catch((error: any) => {
        // TODO: This is a common error because Segment isn't loaded initially.
        // This should be called once on Segment ready event.
        sendSentryError("Error fetching client ids", error);
      });
  }, [router.route]);

  return (
    <ShopContext.Provider
      value={{
        state,
        dispatch,
        clearCart,
        createCart,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};
